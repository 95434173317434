import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { AppointmentService } from '../../../services/appointment.service'
import * as moment from 'moment'
import { AuthService } from '../../../services/auth.service';
import { startOfDay, endOfDay, endOfMonth, startOfMonth, startOfWeek, endOfWeek } from 'date-fns';
import { ToastrService } from 'ngx-toastr';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent
} from 'angular-calendar'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  isBooking: boolean = false;

  appointment;
  modal;
  getBySearch;

  colors: any = {
    completed: {
      primary: '#56F175',
      secondary: '#a0e5ae'
    },
  };

  view: string = 'month';

  activeDayIsOpen: boolean = true;
  viewDate: Date = new Date();
  events;
  dayDate = moment().toDate();

  staff_Id: number;
  getStart: any = {

    month: startOfMonth,
    week: startOfWeek,
    day: startOfDay
  }[this.view];

  getEnd: any = {
    month: endOfMonth,
    week: endOfWeek,
    day: endOfDay
  }[this.view];

  search;
  interval;

  constructor(public appointmentService: AppointmentService, private authService: AuthService, 
    private toastr: ToastrService) {
    this.staff_Id = authService.currentUser.value.staff_Id;
    if (this.staff_Id == null) { this.staff_Id = 0; }
  }

  ngOnInit() {
    this.interval = setInterval((data) => {
      this.loadAppointments();
    }, (1000 * 30));
    this.loadAppointments();
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  // getBySearch(modal) {
  //   this.appointmentService.getBySearch(this.search).then((data) => {
  //     if (data == null) {
  //       this.toastr.warning("No appointments found.");
  //       return;
  //     }

  //     this.search = "";
  //     this.selectedAppointment = data;
  //     this.appointmentRef = this.modalService.open(modal, { centered: true });
  //     this.appointmentRef.result.then((result) => {

  //     });
  //   })
  // }

  loadAppointments() {
    this.activeDayIsOpen = false;
    var startDate = moment(this.getStart(this.viewDate)).format("YYYY/MM/DD");
    var endDate = moment(this.getEnd(this.viewDate)).format("YYYY/MM/DD");

    var eventList = [];
    this.appointmentService.getAppointments(startDate, endDate, this.staff_Id).then((appointments) => {
 
      appointments.forEach(appointment => {
        appointment.start = new Date(appointment.start);
        appointment.end = new Date(appointment.end);
        eventList.push(appointment);

        if (appointment.completed) {
   
          appointment.color = this.colors.completed;
        }
      });
      this.events = eventList;
 
    });
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    this.dayDate = date;
    if ((moment(date).isSame(this.viewDate, 'day') && this.activeDayIsOpen === true) || events.length === 0) {
      this.activeDayIsOpen = false;
    } else {
      this.activeDayIsOpen = true;
      this.viewDate = date;
    }
    // if (isSameMonth(date, this.viewDate)) {
    //   if (
    //     (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
    //     events.length === 0
    //   ) {
    //     this.activeDayIsOpen = false;
    //   } else {
    //     this.activeDayIsOpen = true;
    //     this.viewDate = date;
    //   }
    // }
  }

  // bookingCompleted() {
  //   this.modalRef.close();
  //   this.loadAppointments();
  // }

  // modalRef;
  // appointment(modal, selectedAppointment) {
  //   if (this.appointmentRef) { this.appointmentRef.dismiss(); }
  //   this.selectedAppointment = selectedAppointment;
  //   console.log(selectedAppointment);

  //   this.modalRef = this.modalService.open(modal, { windowClass: "hugeModal", centered: true });
  //   this.modalRef.result.then((result) => {

  //   });

  // }

  // appointmentRef;
  // selectedAppointment = null;
  // handleEvent(event, modal) {
  //   this.appointmentService.getAppointment(event.id).then((data) => {
  //     this.selectedAppointment = data;
  //     console.log(data);
  //     this.appointmentRef = this.modalService.open(modal, { centered: true, size: 'lg' });
  //     this.appointmentRef.result.then((result) => {

  //     });
  //   });
  // }

  // cancel(service) {
  //   this.appointmentRef.dismiss();
  //   this.confirmationService.confirm({
  //     message: 'Are you sure you want to cancel this appointment?',
  //     accept: () => {
  //       this.appointmentService.remove(service.id).then((data) => {
  //         this.loadAppointments();
  //       });
  //     }
  //   });
  // }

  // complete(service) {
  //   this.appointmentRef.dismiss();
  //   this.confirmationService.confirm({
  //     message: 'Are you sure you want to complete this appointment?',
  //     accept: () => {
  //       this.appointmentService.complete(service).then((data) => {
  //         this.loadAppointments();
  //       });
  //     }
  //   });
  // }
}

import { Component, OnInit } from '@angular/core';
import { App } from 'src/app/models/app';
import { AppService } from 'src/app/services/app.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from 'src/app/services/app-config.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  app: App = new App();

  contactForm = {
    name: "",
    email: "",
    message: "",
    app_Id: 0
  }

  constructor(private appService: AppService, private toastr: ToastrService, private http: HttpClient, private config: AppConfigService) { }

  ngOnInit() {
    this.appService.getApp().then((data) => {
      this.app = data;
    });
  }

  sendEmail() {
    if (this.contactForm.name == "") {
      this.toastr.warning("Enter name");
      return;
    }

    if (this.contactForm.email == "") {
      this.toastr.warning("Enter email");
      return
    }

    if (this.contactForm.message == "") {
      this.toastr.warning("Enter message");
      return;
    }

    // get the app id
    this.contactForm.app_Id = this.config.data.app_id;

    this.http.post(this.config.data.url + "email/postcontact", this.contactForm).subscribe(data => {
      this.contactForm = {
        name: "",
        email: "",
        message: "",
        app_Id: 0
      };


      this.toastr.success("Thank you for contacting us.");
    }, error => {
      console.log(error);
    });
  }
}

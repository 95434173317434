import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss']
})
export class StaffComponent implements OnInit {
  @Input() staffList;
  @Output() staffSelected: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit() {
    
  }

  selectStaff(staff) {
    this.staffSelected.emit(staff);
  }

}

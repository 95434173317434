import { Component, OnInit } from '@angular/core';
import { App } from 'src/app/models/app';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppService } from 'src/app/services/app.service';
import { ServicesService } from 'src/app/services/services.service';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogModel, ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-service-categories',
  templateUrl: './service-categories.component.html',
  styleUrls: ['./service-categories.component.scss']
})
export class ServiceCategoriesComponent implements OnInit {

  opened: Boolean = false;
  app: App = new App();
  serviceCategories;

  serviceCategoryForm: FormGroup;
  submitted = false;

  constructor(private appService: AppService, private servicesService: ServicesService, private formBuilder: FormBuilder, public dialog: MatDialog, 
    private toastr: ToastrService) {
    appService.getApp().then((app) => {
      this.app = app;
    });

    servicesService.getServiceCategories().then((categories) => {
      this.serviceCategories = categories;
    });
  }

  ngOnInit() {
    this.serviceCategoryForm = this.formBuilder.group({
      id: [0],
      name: ['', Validators.required],
      description: [''],
      is_Edit: [false],
    });
  }

  onSubmit() {
    if (this.submitted)
      return;

    if (this.serviceCategoryForm.invalid) {
      return;
    }

    this.submitted = true;
    this.servicesService.saveServiceCategory(this.serviceCategoryForm.value).then((result) => {
      this.servicesService.getServiceCategories().then((categories) => {
        this.serviceCategories = categories;
        this.toastr.success("Successfully added service category.");
        this.submitted = false;
        this.opened = false;
      });

    });
  }


  addServiceCategory() {
    this.serviceCategoryForm = this.formBuilder.group({
      id: [0],
      name: ['', Validators.required],
      description: [''],
      is_Edit: [false],
    });
    this.opened = true;
  }

  editServiceCategory(serviceCategory) {
    this.serviceCategoryForm = this.formBuilder.group({
      id: [serviceCategory.id],
      name: [serviceCategory.name, Validators.required],
      description: [serviceCategory.description],
      is_Edit: [true],
    });
    this.opened = true;
  }

  confirmDelete(serviceCategory) {
    const message = "Are you sure you want to delete this service?";
    const dialogData = new ConfirmDialogModel("Delete", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: dialogData });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.servicesService.deleteServiceCategory(serviceCategory).then((result) => {
          this.servicesService.getServiceCategories().then((categories) => {
            this.serviceCategories = categories;
            this.toastr.success("Successfully deleted service category.");
          });
        });
      }
    });
  }

}

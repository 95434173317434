import { OpeningTime } from './openingtime';
import { SpecificOpeningTime } from './specificopeningtime';

export class App {
    id: number = 0;
    name: string = "";
    title: String = "";
    sub_Title: String = "";
    address_Line_1: String = "";
    postcode: String = "";
    phone_No: String = "";
    about_Us: String = "";
    email: String = "";
    publishable_Key: String = "";

    openingTimes: OpeningTime[];
    specificOpeningTimes: SpecificOpeningTime[];
    services = [];
    staff;
}
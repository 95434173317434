import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { AppConfigService } from './services/app-config.service';
import { HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';

import { TeximateModule } from 'ngx-teximate';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MatSidenavModule } from '@angular/material/sidenav'; 
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { DeviceDetectorModule } from 'ngx-device-detector';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HeroComponent } from './components/hero/hero.component';
import { HomeComponent } from './pages/home/home.component';
import { TreatmentsComponent } from './components/treatments/treatments.component';
import { SeperatorComponent } from './components/seperator/seperator.component';
import { AboutComponent } from './components/about/about.component';
import { PriceListComponent } from './components/price-list/price-list.component';
import { BookingBtnComponent } from './components/booking-btn/booking-btn.component';
import { LoginComponent } from './pages/login/login.component';
import { AuthService } from './services/auth.service';
import { AppointmentService } from './services/appointment.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { OpeningHoursComponent } from './components/opening-hours/opening-hours.component';
import { TimeComponent } from './components/opening-hours/time/time.component';
import { ContactComponent } from './components/contact/contact.component';
import { FooterComponent } from './components/footer/footer.component';
import { ServicesService } from './services/services.service';
import { StaffService } from './services/staff.service';
import { OurpartnersComponent } from './components/ourpartners/ourpartners.component';
import { PriceListService } from './services/pricelist.service';
import { QualificationsComponent } from './pages/qualifications/qualifications.component';
import { GalleryComponent } from './pages/gallery/gallery.component';
import { SharedModule } from './shared.module';
import { CommonModule } from '@angular/common';
import { AppointmentsComponent } from './pages/appointments/appointments.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HeroComponent,
    HomeComponent,
    TreatmentsComponent,
    SeperatorComponent,
    AboutComponent,
    PriceListComponent,
    BookingBtnComponent,
    LoginComponent,
    OpeningHoursComponent,
    TimeComponent,
    ContactComponent,
    FooterComponent,
    OurpartnersComponent,
    QualificationsComponent,
    GalleryComponent,
    AppointmentsComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    SharedModule,
    AppRoutingModule,
    CarouselModule, 
    MatSidenavModule,
    TeximateModule,
    HttpClientModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }),
    ScrollToModule.forRoot(),
    NgxSpinnerModule,
    DeviceDetectorModule.forRoot(),
    FormsModule,
  ],
  providers: [
    CookieService,
    AuthService,
    ServicesService,
    AppointmentService,
    StaffService,
    PriceListService,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    AppConfigService, { provide: APP_INITIALIZER, useFactory: (config: AppConfigService) => () => config.load(), deps: [AppConfigService], multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

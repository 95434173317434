import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { AppointmentsComponent } from './pages/appointments/appointments.component';
import { AdminModule } from './admin/admin.module';
import { QualificationsComponent } from './pages/qualifications/qualifications.component';
import { GalleryComponent } from './pages/gallery/gallery.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'qualifications', component: QualificationsComponent },
  { path: 'gallery', component: GalleryComponent },
  { path: 'login', component: LoginComponent },
  { path: 'appointment', component: AppointmentsComponent },
  { path: 'admin', loadChildren: () => AdminModule },
  { 
    path: '',
    redirectTo: '/',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

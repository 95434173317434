import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppService } from './services/app.service';
import { AppointmentComponent } from './components/appointment/appointment.component';
import { ServicesComponent } from './components/appointment/services/services.component';
import { StaffComponent } from './components/appointment/staff/staff.component';
import { AppointmentTimeComponent } from './components/appointment/appointment-time/appointment-time.component';
import { SlotComponent } from './components/appointment/slot/slot.component';
import { ConfirmationComponent } from './components/appointment/confirmation/confirmation.component';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS, MatDatepickerModule } from '@angular/material';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { GroupByPipe } from './pipes/group-by.pipe';
import { SortByPipe } from './pipes/sort-by.pipe';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    GroupByPipe,
    SortByPipe,

    AppointmentComponent,
    AppointmentTimeComponent,
    SlotComponent,
    ConfirmationComponent,
    ServicesComponent,
    StaffComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatDatepickerModule,
  ],
  exports: [
    GroupByPipe,
    SortByPipe,
    
    AppointmentComponent,
    AppointmentTimeComponent,
    SlotComponent,
    ConfirmationComponent,
    ServicesComponent,
    StaffComponent
  ],
  providers: [
    AppService,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class SharedModule { }

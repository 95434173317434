import { Component, OnInit } from '@angular/core';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { PriceListService } from 'src/app/services/pricelist.service';

@Component({
  selector: 'app-treatments',
  templateUrl: './treatments.component.html',
  styleUrls: ['./treatments.component.scss']
})
export class TreatmentsComponent implements OnInit {

  constructor(private scrollToService: ScrollToService, private priceListService: PriceListService) {

  }

  ngOnInit() {
  }

  scrollTo(destination) {
    this.priceListService.openPriceList(destination);
    const config: ScrollToConfigOptions = {
      target: destination,
      offset: -50
    };

    this.scrollToService.scrollTo(config);
  }
}

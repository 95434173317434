import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from "rxjs/operators";

@Injectable()
export class AppConfigService {
  public data: AppConfig = null;

  constructor(private http: HttpClient) { }

  public load() {
    return new Promise((resolve, reject) => {
      return this.http.get("/assets/config.json").toPromise().then((data: any) => {
        this.data = new AppConfig();
        this.data = data;
        resolve(true);

      }).catch((error) => {
        console.log(error);
        reject(error);
      });
    });
  }
}

export class AppConfig {
  url: string;
  app_id: number;
}

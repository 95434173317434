import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';;
import { AppConfigService } from 'src/app/services/app-config.service';

@Injectable()
export class StaffService {
    constructor(private http: HttpClient, private config: AppConfigService) { }

    public getStaff(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.get(this.config.data.url + "staff/get?app_id=" + this.config.data.app_id).subscribe(data => {
                resolve(data);
            }, error => {
                console.log(error);
                reject();
            })
        });
    }

    public saveStaff(staff): Promise<any> {
        return new Promise((resolve, reject) => {
            staff.app_Id = this.config.data.app_id;
            if (staff.is_Edit) {
                this.http.put(this.config.data.url + "staff/put", staff).subscribe(data => {
                    resolve(data);
                }, error => {
                    console.log(error);
                    reject();
                });
            } else {
                this.http.post(this.config.data.url + "staff/post", staff).subscribe(data => {
                    resolve(data);
                }, error => {
                    console.log(error);
                    reject();
                });
            }

        });
    }

    public deleteStaff(staff): Promise<Boolean> {
        return new Promise((resolve, reject) => {
            this.http.delete(this.config.data.url + "staff/delete?id=" + staff.id).subscribe((data) => {
                resolve(true);
            }, error => {
                console.log(error);
                reject();
            });
        });
    }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { App } from 'src/app/models/app';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent implements OnInit {
  @Input() isAdmin: boolean;
  
  @Output() confirmed: EventEmitter<any> = new EventEmitter();

  app: App = new App();
  state: number = 1;

  staffList = [];
  fullStaffList = [];

  selectedService: any;
  selectedStaff: any;
  selectedDate: any;
  selectedSlot: any;

  constructor(private appService: AppService) {
    this.appService.getApp().then((data) => {
      this.app = data;
      this.fullStaffList = this.app.staff;
    })
  }

  ngOnInit() {
    
  }

  appointmentBooked() {
    this.confirmed.emit();
  }

  changeState(newState) {
    if (newState > this.state)
      return;

    if (newState == 2 && this.fullStaffList.length == 1) {
      this.selectedStaff = this.fullStaffList[0];
      newState = 3;
    }

    this.state = newState;
  }

  serviceSelected(service) {
    this.getStaff(service);
    this.selectedService = service;
    if (this.fullStaffList.length == 1) {
      this.selectedStaff = this.fullStaffList[0];
      this.state = 3;
      return;
    }

    this.state++;
  }

  staffSelected(staff) {
    this.selectedStaff = staff;
    this.state++;    
  }


  getStaff(service) {
    this.staffList = [];
    for (var staff of this.app.staff) {
      // Check if this staff member has this service
      for (var staffService of staff.staffService) {
        if (staffService.serviceId == service.id) {
          this.staffList.push(staff);
          break;
        }
      }
    }
  }

  dateSelected(selectedDate: any) {
    this.selectedDate = selectedDate;
    this.state++;
  }

  slotSelected(selectedSlot: any) {
    this.selectedSlot = selectedSlot;
    this.state++;
  }
}

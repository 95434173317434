import { Component, PLATFORM_ID, APP_ID, Inject, OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common'
import { AuthService } from './services/auth.service';
import { User } from './models/user';
import { AppService } from './services/app.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';

declare var stripe: any;
declare function setStripe(key): any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Studio 42';

  opened: boolean = false;
  user: User;
  isAdmin: boolean = false;
  isStaff: boolean = false;

  constructor(private authService: AuthService, private appService: AppService, private router: Router, 
    private route: ActivatedRoute, private scrollToService: ScrollToService, @Inject(PLATFORM_ID) private platformId: Object) {
    this.authService.getUser();
    this.authService.currentUser.subscribe((user) => {
      this.user = user;
      if (user != null)
        this.isAdmin = this.authService.isAdmin();
        this.isStaff = this.authService.isStaff();
    });

    this.appService.getApp().then((app) => {
      setStripe(app.publishable_Key);
    });

    // this.router.events.subscribe((evt) => {
    //   if (!(evt instanceof NavigationEnd)) {
    //     return;
    //   }
    //   var scrollToTop = window.setInterval(function () {
    //     var pos = window.pageYOffset;
    //     if (pos > 0) {
    //       window.scrollTo(0, pos - 20); // how far to scroll on each step
    //     } else {
    //       window.clearInterval(scrollToTop);
    //     }
    //   }, 16); // how fast to scroll (this equals roughly 60 fps)
    // })
  }

  onActivate(event: any) {
    if (isPlatformBrowser(this.platformId)) {
      let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {
          window.scrollTo(0, pos - 50); // how far to scroll on each step
        } else {
          window.clearInterval(scrollToTop);
        }
      }, 16);
    }
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params["dest"] != undefined) {
        setTimeout(() => {
          this.scrollToTarget(params["dest"]);
        }, 1500);
      }
    });
  }

  scrollToTarget(target) {
    // If the page isn't currently the home page, then we need to reroute to the home page
    const config: ScrollToConfigOptions = {
      target: target,
      offset: -100
    };
 
    this.scrollToService.scrollTo(config);
  }

  toggleMenu() {
    this.opened = !this.opened;
  }

  navSelected(target) {
    this.opened = false;
    if (this.router.url != "/" && target != null) {
      this.router.navigate(["/"], { queryParams: { dest: target }});
    } else {
      this.scrollToTarget(target);
    }
  }

  logout() {
    this.authService.logout();
    this.opened = false;
  }
}

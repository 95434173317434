import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PriceListService {
    togglePriceList: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor() { }

    openPriceList(destination) {
        this.togglePriceList.next(destination);
    }
}

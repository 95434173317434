import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { HttpClient } from '@angular/common/http';
import { App } from 'src/app/models/app';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { AppConfigService } from 'src/app/services/app-config.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  app: App = new App();
  loginModel: LoginModel = new LoginModel();
  registerModel: RegisterModel = new RegisterModel();
  
  state = 1;

  errorMessage = "";
  code;
  userId;

  constructor(private appService: AppService, private http: HttpClient, private route: ActivatedRoute,
    private router: Router, private auth: AuthService, private config: AppConfigService) { 
    this.appService.getApp().then((app) => {
      this.app = app;
    })
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.userId = params['userId'];
      this.code = params['code'];
      if (this.userId != undefined) {
        this.state = 4;
      }
    });
  }

  login() {
    this.errorMessage = "";
 
    this.http.post(this.config.data.url + "account/login", this.loginModel).subscribe(data => {
      debugger;
      this.auth.setUser(data);
      this.router.navigate(['/']);
    }, error => {
      this.errorMessage = "Invalid email or password";
    })
  }

  register() {
    this.errorMessage = "";
    if (this.registerModel.Password != this.registerModel.Confirm_Password) {
      this.errorMessage = "Passwords do not match.";
      this.registerModel.Passwords_Match = false;
      return;
    }

    this.registerModel.Passwords_Match = true;

    this.registerModel.App_Id = this.config.data.app_id;
    this.http.post(this.config.data.url + "account/register", this.registerModel).subscribe(data => {
      this.auth.setUser(data);
      this.router.navigate(['/']);
    }, error => {
      console.log(error);
      this.errorMessage = error;
    })
  }

  sendPasswordReset(email) {
    this.loginModel.Email = email;
    this.loginModel.New = true;
    this.errorMessage = "";
   
    this.http.post(this.config.data.url + "account/resetpassword?URL=" + window.location.origin, this.loginModel).subscribe(data => {
      this.errorMessage = "Please check your email to reset your password."
    }, error => {
   
    })
  }

  confirmResetPassword(password, confirmPassword) {
    this.errorMessage = "";
    if (password != confirmPassword) {
      this.errorMessage = "Passwords do not match.";
      return;
    }


    var model = {
      Id: this.userId,
      Password: password,
      Code: this.code
    };

    this.http.post(this.config.data.url + "account/changepassword", model).subscribe(data => {
      this.router.navigate(["/login"])
      this.state = 1;
    }, error => {
      console.log(error);
    })
    
  }
}

export class LoginModel {
  Email: string;
  Password: string;
  New: boolean;
}

export class RegisterModel {
  Firstname: string;
  Lastname: string;
  Email: string;
  Phone: string;
  Password: string;
  Confirm_Password: string;
  App_Id: Number;

  Passwords_Match: boolean = null;
}

import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @Output() toggleMenu: EventEmitter<any> = new EventEmitter();

  user: User;
  isAdmin: boolean = false;

  constructor(private router: Router, private scrollToService: ScrollToService, private authService: AuthService) {
    this.authService.currentUser.subscribe((user) => {
      this.user = user;
      if (user != null)
        this.isAdmin = this.authService.isAdmin();
      else
        this.isAdmin = false;

        console.log(this.isAdmin);
    });
  }

  ngOnInit() {
  }

  toggle() {
    this.toggleMenu.emit();
  }

  scrollToTarget(target) {
    // If the page isn't currently the home page, then we need to reroute to the home page
    const config: ScrollToConfigOptions = {
      target: target,
      offset: -100
    };

    this.scrollToService.scrollTo(config);
  }

  navSelected(target) {
    if (this.router.url != "/" && target != null) {
      this.router.navigate(["/"], { queryParams: { dest: target } });
    } else {
      this.scrollToTarget(target);
    }
  }

  logout() {
    this.authService.logout();
  }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { App } from 'src/app/models/app';
import { AppService } from 'src/app/services/app.service';
import { ServicesService } from 'src/app/services/services.service';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogModel, ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'admin-app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class AdminServicesComponent implements OnInit {
  opened: Boolean = false;
  app: App = new App();
  servicesAll;
  services;
  serviceCategories;

  serviceForm: FormGroup;
  submitted = false;

  constructor(private appService: AppService, private servicesService: ServicesService, private formBuilder: FormBuilder, public dialog: MatDialog, 
    private toastr: ToastrService) {
    appService.getApp().then((app) => {
      this.app = app;
    });

    servicesService.getServices().then((services) => {
      this.servicesAll = services;
      this.services = services;
    });

    servicesService.getServiceCategories().then((categories) => {
      this.serviceCategories = categories;
    });
  }

  ngOnInit() {
    this.initForm();
  }

  filterCategory(data) {
    var id = data.value;
    this.services = this.servicesAll.filter(a => a.serviceCategory_Id == id);
  }

  initForm() {
    this.serviceForm = this.formBuilder.group({
      id: [0],
      name: ['', Validators.required],
      description: [''],
      time: ['', Validators.required],
      cost: ['', Validators.required],
      upfront: [0],
      featured: [false],
      enquiry_Only: [false],
      serviceCategory_Id: ['', Validators.required],
      is_Edit: [false],
    });
  }

  addService() {
    this.initForm();
    this.opened = true;
  }

  editService(service) {
    this.serviceForm = this.formBuilder.group({
      id: [service.id],
      name: [service.name, Validators.required],
      description: [service.description],
      time: [service.time, Validators.required],
      cost: [service.cost, Validators.required],
      upfront: [service.upfront],
      featured: [false],
      enquiry_Only: [false],
      serviceCategory_Id: [service.serviceCategory_Id, Validators.required],
      is_Edit: [true],
    });
    this.opened = true;
  }

  onSubmit() {
    if (this.submitted)
      return;

    if (this.serviceForm.invalid) {
      return;
    }

    this.submitted = true;
    this.servicesService.saveService(this.serviceForm.value).then((result) => {
      this.servicesService.getServices().then((services) => {
        this.servicesAll = services;
        this.services = services;
        this.toastr.success("Successfully added service.");
        this.submitted = false;
        this.opened = false;
      });

    });
  }

  confirmDelete(serviceCategory) {
    const message = "Are you sure you want to delete this service?";
    const dialogData = new ConfirmDialogModel("Delete", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: dialogData });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.servicesService.deleteService(serviceCategory).then((result) => {
          this.servicesService.getServices().then((services) => {
            this.servicesAll = services;
            this.services = services;
            this.toastr.success("Successfully deleted service.");
          });
        });
      }
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-booking-btn',
  templateUrl: './booking-btn.component.html',
  styleUrls: ['./booking-btn.component.scss']
})
export class BookingBtnComponent implements OnInit {

  constructor(private authService: AuthService, private router: Router, private toastr: ToastrService) { }

  ngOnInit() {
  }

  navigateToBooking() {
    // check if the user is logged in when booking
    if (!this.authService.isLoggedIn()) {
      this.toastr.warning("Must be logged in to book");
      this.router.navigate(["login"]);
    } else {
      this.router.navigate(["appointment"]);
    }
      
  }

}

import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { App } from 'src/app/models/app';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  @Output() serviceSelected: EventEmitter<any> = new EventEmitter();

  app: App = new App();
  categories = [];
  constructor(private appService: AppService) { 
    this.appService.getApp().then((data) => {
      this.app = data;

      for (let service of this.app.services) {
        var category = this.categories.find(a => a.id == service.serviceCategory.id);
        if (category == null) {
          category = service.serviceCategory;
          category.services = [];
          category.expanded = false;
          this.categories.push(category);
        }
          
        category.services.push(service);
      }

      this.categories[0].expanded = true;
    })
  }

  ngOnInit() {
  }

  toggleCategory(category) {
    if (category.expanded)
      return;

    for (let category of this.categories) {
      category.expanded = false;
    }

    category.expanded = true;
  }

  selectService(service) {
    this.serviceSelected.emit(service);
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ServiceCategoriesComponent } from './pages/service-categories/service-categories.component';
import { AdminServicesComponent } from './pages/services/services.component';
import { StaffComponent } from './pages/staff/staff.component';

const routes: Routes = [
  { path: '', component: DashboardComponent },
  { path: 'dashboard', component: DashboardComponent, data: { title: "Dashboard" } },
  { path: 'servicecategories', component: ServiceCategoriesComponent, data: { title: "Service Categories" } },
  { path: 'services', component: AdminServicesComponent, data: { title: "Services" } },
  { path: 'staff', component: StaffComponent, data: { title: "Staff" } },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { App } from 'src/app/models/app';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-opening-hours',
  templateUrl: './opening-hours.component.html',
  styleUrls: ['./opening-hours.component.scss']
})
export class OpeningHoursComponent implements OnInit {

  app: App = new App();
  
  constructor(private appService: AppService) { 
    appService.getApp().then((data) => {
      this.app = data;
    });
  }

  ngOnInit() {
  }

}

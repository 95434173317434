import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-ourpartners',
  templateUrl: './ourpartners.component.html',
  styleUrls: ['./ourpartners.component.scss']
})
export class OurpartnersComponent implements OnInit {

  sliders: any[] = [];

  customOptions: OwlOptions = {
    items: 1,
    loop: true,
    autoplay: true,
    autoplayTimeout: 2000,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
  }

  constructor(private deviceService: DeviceDetectorService) { 
    this.sliders.push({image: "partner1.jpg" });
    this.sliders.push({image: "partner2.jpg" });
    this.sliders.push({image: "partner3.jpg" });
    this.sliders.push({image: "partner4.jpg" });
    this.sliders.push({image: "partner5.jpg" });
    this.sliders.push({image: "partner6.jpg" });
    this.sliders.push({image: "partner7.jpg" });
    this.sliders.push({image: "partner8.jpg" });
    this.sliders.push({image: "partner9.jpg" });
    this.sliders.push({image: "partner11.jpg" });

    if (this.deviceService.isDesktop()) {
      this.customOptions.items = 4;
    }
  }

  ngOnInit() {
   
  }

}

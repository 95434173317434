import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  aboutUs: String = "";
  constructor(private appService: AppService) { 
    this.appService.getApp().then((app) => {
      this.aboutUs = app.about_Us;
    });
  }

  ngOnInit() {
  }

}

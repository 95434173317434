import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { PriceListService } from 'src/app/services/pricelist.service';

@Component({
  selector: 'app-price-list',
  templateUrl: './price-list.component.html',
  styleUrls: ['./price-list.component.scss']
})
export class PriceListComponent implements OnInit {
  categories = [];

  list1 = [];
  list2 = [];
  
  constructor(private appService: AppService, private priceListService: PriceListService) {

    this.priceListService.togglePriceList.subscribe((service) => {
      if (service == null)
        return;
        
      for (var category of this.categories) {
        if (category.name == service) {
          category.isSelected = true;
        }
      }
    });

    this.appService.getApp().then((app) => {
      for (let service of app.services) {
        if (service.is_Course || service.epoS_Only) {
          continue;
        }
          
        var category = this.categories.find(a => a.id == service.serviceCategory.id);
        if (category == null) {
          category = service.serviceCategory;
          category.services = [];
          this.categories.push(category);
        }
          
        category.services.push(service);
      }

      for (var i = 0; i < this.categories.length; i++) {
        if (this.isLaser(this.categories[i].name))
          this.list1.push(this.categories[i]);
        else
          this.list2.push(this.categories[i]);
      }
    })
  }

  ngOnInit() {
  }

  categoryToggle(category) {
    if (category.isSelected == null)
      category.isSelected = false;

    category.isSelected = !category.isSelected;
  }

  isLaser(name: string) {
    var treatments = [
      "Comcit Elite",
      "Soprano Ice Platinum",
      "Microdermabrasion",
      "Laser Treatments"
    ]

    if (treatments.includes(name)) {
      return true;
    }

    return false;
  }
}

import { Component, OnInit, HostListener } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { TextAnimation } from 'ngx-teximate';
import { fadeInDown, bounceInUp, bounceIn, bounceInDown } from 'ng-animate';
import { AppService } from 'src/app/services/app.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DomSanitizer} from '@angular/platform-browser'

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements OnInit {

  topBar = 100;
  screenHeight = 0;
  screenWidth = 0;

  sliders: any[] = [];

  isMobile;
  isTablet;
  isDesktop;

  customOptions: OwlOptions = {
    items: 1,
    loop: true,
    autoplay: true,
    //autoplayTimeout: 3000,
    //autoplayTimeout: 14000,
    autoplayTimeout: 14000,
    animateIn: 'fadeIn', // add this
    animateOut: 'fadeOut' // and this,
  }
  constructor(private appService: AppService, private deviceService: DeviceDetectorService, private domSanitizer: DomSanitizer) {
    this.appService.getApp().then((app) => {
      this.sliders.push({
        isVideo: true,
        video: "hannahs2.mp4", 
      });
      this.sliders.push({
        isVideo: false,
        image: "slider1.jpg", 
        class: "item-white"
      });
      this.sliders.push({
        isVideo: false,
        image: "slider2.jpg", 
        class: "item-white"
      });
      this.sliders.push({
        isVideo: false,
        image: "slider3.jpg", 
        class: "item-white"
      });
      this.sliders.push({
        isVideo: false,
        image: "slider4.jpg", 
        class: "item-white"
      });
      this.sliders.push({
        isVideo: false,
        image: "slider5.jpg", 
        class: "item-white"
      });
      // this.sliders.push({
      //   isVideo: false,
      //   image: "slider2.jpg", 
      //   subtitle: app.name == null ? "" : app.name,
      //   title: app.title == null ? "" : app.title,
      //   info: app.sub_Title == null ? "" : app.sub_Title,
      //   link: "#",
      //   linkText: "Preston's Newest Prestine Laser Bar & Beauty Clinic",
      //   class: "item-white",

      // });
      // this.sliders.push({
      //   isVideo: false,
      //   image: "slider3.jpg", 
      //   link: "#",
      //   linkText: "",
      //   class: "item-white"
      // });
      // this.sliders.push({
      //   isVideo: false,
      //   image: "slider4.jpg", 
      //   link: "#",
      //   linkText: "",
      //   class: "item-white"
      // });
      // this.sliders.push({
      //   isVideo: false,
      //   image: "slider5.jpg", 
      //   link: "#",
      //   linkText: "",
      //   class: "item-white"
      // });
    });
    this.setCarousalHeight(window.innerHeight);
    
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktop = this.deviceService.isDesktop();
  }

  ngOnInit() {
  }

  getVideoTag(url) {
    return this.domSanitizer.bypassSecurityTrustHtml(
      `<video autoplay muted>
          <source src="${url}" type="video/mp4">No HTML5 supported.</source>
       </video>`
    );
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setCarousalHeight(window.innerHeight);
  }

  setCarousalHeight(height: number) {
    this.screenHeight = height - this.topBar - 50;
  }

  topAnimation: TextAnimation = {
    animation: bounceInUp,
    delay: -300,
    type: 'paragraph'
  };

  centerAnimation: TextAnimation = {
    animation: bounceIn,
    delay: -300,
    type: 'paragraph'
  };

  bottomAnimation: TextAnimation = {
    animation: bounceInDown,
    delay: -300,
    type: 'paragraph'
  };
}

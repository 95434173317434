import { Component, OnInit } from '@angular/core';
import { StaffService } from 'src/app/services/staff.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/services/app.service';
import { ConfirmDialogModel, ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss']
})
export class StaffComponent implements OnInit {
  opened: Boolean = false;
  staffs: [];
  servicesAll;
  services;

  staffForm: FormGroup;
  staffServices: any = [];
  submitted = false;
  selectedService = "0";

  constructor(private appService: AppService, private staffService: StaffService, private formBuilder: FormBuilder, public dialog: MatDialog, 
    private toastr: ToastrService) {
    this.staffService.getStaff().then((staff) => {
      this.staffs = staff;
    });

    this.appService.getApp().then((app) => {
      this.servicesAll = app.services;
      this.services = app.services;
    });
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.staffServices = [];
    this.staffForm = this.formBuilder.group({
      id: [0],
      firstname: [, Validators.required],
      lastname: ['', Validators.required],
      email: ['', ],
      password: [''],
      selectedService: ['0'],
      is_Edit: [false],
    });
  }

  addStaff() {
    this.initForm();
    this.opened = true;
  }

  editStaff(staff) {
    this.staffServices = [];
    
    for (let service of staff.staffService) {
      this.staffServices.push(service.service);
    }

    this.services = this.servicesAll.filter((item) => {
      return this.staffServices.some(a => a.id == item.id) == false;
    });

    this.staffForm = this.formBuilder.group({
      id: [staff.id],
      firstname: [staff.firstname, Validators.required],
      lastname: [staff.lastname, Validators.required],
      email: [staff.email],
      password: [''],
      services: [staff.staffService],
      selectedService: ['0'],
      is_Edit: [true],
    });
    this.opened = true;
  }

  addService() {
    var service = this.staffForm.value.selectedService;
    if (this.staffServices.filter(a => a.id == service.id).length > 0)
      return;

    this.staffServices.push(this.staffForm.value.selectedService);
    this.services = this.servicesAll.filter((item) => {
      return this.staffServices.some(a => a.id == item.id) == false;
    });
  }

  removeService(service) {
    var serviceToRemove = this.staffServices.find(a => a.id == service.id);
    this.staffServices.splice(this.staffServices.indexOf(serviceToRemove));

    this.services = this.servicesAll.filter((item) => {
      return this.staffServices.some(a => a.id == item.id) == false;
    });
  }

  onSubmit() {
    if (this.submitted)
      return;

    if (this.staffForm.invalid) {
      return;
    }

    this.submitted = true;
    this.staffForm.value.staffService = [];
    this.staffForm.value.services = this.staffServices;
    this.staffService.saveStaff(this.staffForm.value).then((result) => {
      this.staffService.getStaff().then((staff) => {
        this.staffs = staff;
        this.toastr.success("Successfully added staff.");
        this.submitted = false;
        this.opened = false;
      });

    });
  }

  confirmDelete(serviceCategory) {
    const message = "Are you sure you want to delete this staff member?";
    const dialogData = new ConfirmDialogModel("Delete", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: dialogData });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.staffService.deleteStaff(serviceCategory).then((result) => {
          this.staffService.getStaff().then((staff) => {
            this.staffs = staff;
            this.toastr.success("Successfully added staff.");
            this.submitted = false;
            this.opened = false;
          });
        });
      }
    });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppConfigService } from './app-config.service'
import { BehaviorSubject } from 'rxjs';
import { App } from '../models/app';

import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AppointmentService {
  private app: App = null;
  constructor(private http: HttpClient, private config: AppConfigService, private toastr: ToastrService) { }

  public getBookableMessage(appointment_Date: Date): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(this.config.data.url + "appointment/getbookablemessage?app_id=" + this.config.data.app_id + "&appointment_Date=" + moment(appointment_Date).format("YYYY/MM/DD")).subscribe(data => {
        if (data == null)
          resolve();
        else
          resolve(data.value)
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getAppointment(appointment_Id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<String[]>(this.config.data.url + "appointment/getappointment?appointment_id=" + appointment_Id).subscribe(data => {
        resolve(data)
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getAppointmentSlots(staff_Id: Number, service_Id: Number, appointment_Date: Date): Promise<String[]> {
    return new Promise((resolve, reject) => {
      this.http.get<String[]>(this.config.data.url + "appointment/getappointmenttimes?app_id=" + this.config.data.app_id + "&appointment_Date=" + moment(appointment_Date).format("YYYY/MM/DD") + "&staff_Id=" + staff_Id + "&service_Id=" + service_Id).subscribe(data => {
        resolve(data)
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getMultiAppointmentSlots(staff_Id: Number, service_Id: Number, fromDate, toDate): Promise<String[]> {
    var params = "?fromDate=" + fromDate.toISOString();
    params += "&toDate=" + toDate.toISOString();
    params += "&app_Id=" + this.config.data.app_id;
    params += "&staff_Id=" + staff_Id;
    params += "&service_Id=" + service_Id;

    return new Promise((resolve, reject) => {
      this.http.get<String[]>(this.config.data.url + "appointment/GetMultiAppointmentTimes" + params).subscribe(data => {
        resolve(data)
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public bookAppointment(appointment): Promise<any> {
    return new Promise((resolve, reject) => {
      appointment.app_Id = this.config.data.app_id;
      this.http.post(this.config.data.url + "appointment/post", appointment).subscribe(data => {
        resolve(data);
        this.toastr.success("Successfully booked appointment.");
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getAppointments(startDate: string, endDate: string, staff_Id: number): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.http.get<any[]>(this.config.data.url + "appointment/GetAppointments?app_id=" + this.config.data.app_id + "&startDate=" + startDate + "&endDate=" + endDate + "&staff_Id=" + staff_Id).subscribe(data => {
        resolve(data)
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getAppointmentsTable(startDate: string, endDate: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.http.get<any[]>(this.config.data.url + "appointment/GetAppointmentsTable?app_id=" + this.config.data.app_id + "&startDate=" + startDate + "&endDate=" + endDate).subscribe(data => {
        resolve(data)
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public getBySearch(search: String): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any[]>(this.config.data.url + "appointment/GetBySearch?search=" + search).subscribe(data => {
        resolve(data)
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  remove(appointment_Id: Number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.delete<any[]>(this.config.data.url + "appointment/delete?id=" + appointment_Id).subscribe(data => {
        this.toastr.success("Successfully cancelled appointment.");
        resolve();
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  complete(appointment): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any[]>(this.config.data.url + "appointment/complete?appointment_Id=" + appointment.id).subscribe(data => {
        this.toastr.success("Successfully completed appointment.");
        resolve();
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  public saveAppointment(appointment) : Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.config.data.url + "appointment/post", appointment).subscribe(data => {
        resolve(data)
      }, error => {
        console.log(error);
        reject();
      });
    });
  }
}

import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

import { User } from '../models/user'
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AuthService {
  currentUser: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  constructor( private cookieService: CookieService, private router: Router) { }

  public setUser(user) {
    debugger;
    this.cookieService.set("User", JSON.stringify(user), 1);
    this.currentUser.next(user);
  }

  public getUser() : BehaviorSubject<User> {
    if (this.currentUser.value != null) {
      this.currentUser.next(this.currentUser.value);
    }

    var cookieExists = this.cookieService.check("User");
    if (!cookieExists)
      return null;

    var cookieUser = this.cookieService.get("User");
    this.currentUser.next(<User>JSON.parse(cookieUser));
  }

  public logout() {
    this.currentUser.next(null);
    this.cookieService.delete("User");

    this.router.navigate(['/']);
  }

  public isAdmin() {
    if (this.currentUser.value == null)
      return false;

    return this.currentUser.value.isAdmin;
  }

  public isStaff() {
    if (this.currentUser.value == null)
      return false;

    return this.currentUser.value.isAdmin == false && this.currentUser.value.staff_Id > 0;
  }

  public isLoggedIn() {
    this.getUser();
    
    if (this.currentUser.value == null)
      return false;

    return true;
  }
}

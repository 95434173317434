import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-seperator',
  templateUrl: './seperator.component.html',
  styleUrls: ['./seperator.component.scss']
})
export class SeperatorComponent implements OnInit {
  @Input() flip: boolean;

  constructor() { }

  ngOnInit() {
    
  }

}

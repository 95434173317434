import { Component, OnInit, Input, ElementRef, ViewChild, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { App } from 'src/app/models/app';
import { AppService } from 'src/app/services/app.service';

declare var stripe: any;
declare var elements: any;

import * as moment from 'moment';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppointmentService } from 'src/app/services/appointment.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  @ViewChild('cardInfo') cardInfo: ElementRef;

  @Input() isAdmin: boolean;
  @Input() staff: any;
  @Input() service: any;
  @Input() selectedDate: any;
  @Input() slot: any;

  @Output() appointmentBooked: EventEmitter<any> = new EventEmitter();

  app: App;

  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;

  notes = "";
  name = "";
  phone = "";

  constructor(private cd: ChangeDetectorRef, private appService: AppService, private authService: AuthService, private router: Router,
    private toastr: ToastrService, private spinner: NgxSpinnerService, private appointmentService: AppointmentService) {
    this.appService.getApp().then((app) => {
      this.app = app;
    });
  }

  ngOnInit() {
 
  }

  ngOnDestroy() {
    if (this.card != undefined) {
      this.card.destroy();
    }
  }

  ngAfterViewInit() {
    try {
      this.card = elements.create('card', {
        hidePostalCode: true
      });

      this.card.mount(this.cardInfo.nativeElement);

      this.card.addEventListener('change', this.cardHandler);
    } catch (ex) {
      console.log(ex);
      console.log("ERROR MOUNTING CARD");
    }
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  async book() {
    this.spinner.show();
    if (this.service.upfront != null && this.service.upfront > 0) {
      if (this.card == null) {
        this.spinner.hide();
        this.toastr.warning("Enter card");
        return;
      }
    }

    const { token, error } = await stripe.createToken(this.card);
    if (error != null && this.service.upfront != null && this.service.upfront > 0) {
      this.spinner.hide();
      this.toastr.warning(error.message);
      return;
    }


    var appointment = {
      app_Id: this.app.id,
      staff_Id: this.staff.id,
      service_Id: this.service.id,
      appointment_At: this.slot.appointment_Time,
      applicationUser_Id: this.authService.currentUser.value.id,
      notes: this.notes,
      token: token == null ? null : token.id,
      name: this.name,
      phone: this.phone,
    }

    this.appointmentService.saveAppointment(appointment).then((response) => {
      this.spinner.hide();
      this.toastr.success("Appointment booked");
      if (this.isAdmin) {
        this.appointmentBooked.emit();
      } else {
        this.router.navigate(["/"]);
      }
    }, (error) => {
      this.spinner.hide();
      this.toastr.warning(error);
    })
  }

}

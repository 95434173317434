import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';;
import { AppConfigService } from 'src/app/services/app-config.service';

@Injectable()
export class ServicesService {
    constructor(private http: HttpClient, private config: AppConfigService) { }

    public getServices(): Promise<any> {
        return new Promise((resolve, reject) => {
            console.log(this.config);
            this.http.get(this.config.data.url + "service/get?app_id=" + this.config.data.app_id).subscribe(data => {
                resolve(data);
            }, error => {
                console.log(error);
                reject();
            })
        });
    }

    public deleteService(service): Promise<Boolean> {
        return new Promise((resolve, reject) => {
            this.http.delete(this.config.data.url + "service/delete?id=" + service.id).subscribe((data) => {
                resolve(true);
            }, error => {
                console.log(error);
                reject();
            });
        });
    }

    public saveService(service): Promise<any> {
        return new Promise((resolve, reject) => {
            service.app_Id = this.config.data.app_id;
            if (service.is_Edit) {
                this.http.put(this.config.data.url + "service/put", service).subscribe(data => {
                    resolve(data);
                }, error => {
                    console.log(error);
                    reject();
                });
            } else {
                this.http.post(this.config.data.url + "service/post", service).subscribe(data => {
                    resolve(data);
                }, error => {
                    console.log(error);
                    reject();
                });
            }

        });
    }

    public getServiceCategories(): Promise<any> {
        return new Promise((resolve, reject) => {
            console.log(this.config);
            this.http.get(this.config.data.url + "servicecategory/get?app_id=" + this.config.data.app_id).subscribe(data => {
                resolve(data);
            }, error => {
                console.log(error);
                reject();
            })
        });
    }

    public deleteServiceCategory(service): Promise<Boolean> {
        return new Promise((resolve, reject) => {
            this.http.delete(this.config.data.url + "servicecategory/delete?id=" + service.id).subscribe((data) => {
                resolve(true);
            }, error => {
                console.log(error);
                reject();
            });
        });
    }

    public saveServiceCategory(service): Promise<any> {
        return new Promise((resolve, reject) => {
            service.app_Id = this.config.data.app_id;
            if (service.is_Edit) {
                this.http.put(this.config.data.url + "servicecategory/put", service).subscribe(data => {
                    resolve(data);
                }, error => {
                    console.log(error);
                    reject();
                });
            } else {
                console.log(service);
                this.http.post(this.config.data.url + "servicecategory/post", service).subscribe(data => {
                    resolve(data);
                }, error => {
                    console.log(error);
                    reject();
                });
            }

        });
    }
}

import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { App } from 'src/app/models/app';
import { AppService } from 'src/app/services/app.service';

import * as moment from 'moment';
import { AppConfigService } from 'src/app/services/app-config.service';
import { AppointmentService } from 'src/app/services/appointment.service';

@Component({
  selector: 'app-appointment-time',
  templateUrl: './appointment-time.component.html',
  styleUrls: ['./appointment-time.component.scss']
})
export class AppointmentTimeComponent implements OnInit {

  @Output() dateSelected: EventEmitter<any> = new EventEmitter();

  app: App = new App();
  slots = [];
  minDate = moment();

  constructor(private appService: AppService, private appointmentService: AppointmentService, private config: AppConfigService) {
   
  }

  ngOnInit() {
    this.appService.getApp().then((app) => {
      // Get our appointments
      this.app = app;
    })
  }

  closingDateFilters = (d: any): boolean => {
    var openingTime = this.app.openingTimes.find(a => a.day == d.day());
    if (openingTime.isClosed)
      return false;

    for (var specificDate of this.app.specificOpeningTimes) {
      if (moment(specificDate.date).isSame(d)) {
        if (specificDate.isClosed)
          return false;
      }
    }

    return true;
  }

  dateClasses = (d: any): string => {
    for (var specificDate of this.app.specificOpeningTimes) {
      if (moment(specificDate.date).isSame(d)) {
        if (!specificDate.isClosed)
          return "mat-reduced-hours";
      }
    }
    return "";
  }

  dateChanged(d) {
    this.dateSelected.emit(d.toDate());
  }
}

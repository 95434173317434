import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AppConfigService } from './app-config.service'
import { BehaviorSubject } from 'rxjs';
import { App } from '../models/app';

@Injectable()
export class AppService {
  private app: App = null;
  constructor(private http: HttpClient, private config: AppConfigService) { }

  public getApp(): Promise<App> {
    return new Promise((resolve, reject) => {
      // if (this.app != null) {
      //   resolve(this.app);
      //   return;
      // }

      this.http.get<App>(this.config.data.url + "app/get?id=" + this.config.data.app_id).subscribe(data => {
        this.app = data;
        resolve(data);
      }, error => {
        console.log(error);
        reject();
      })
    });
  }

  public saveApp(app: App): Promise<App> {
    return new Promise((resolve, reject) => {
      this.http.put<App>(this.config.data.url + "app/put", app).subscribe(data => {
        this.app = app;
        resolve(data);
      }, error => {
        reject();
      });
    });
  }

  public getAppointments(app_Id: number, startDate: string, endDate: string, staff_Id: number): Promise<any> {
    var params = "&startDate=" + startDate + "&endDate=" + endDate + "&staff_Id=" + staff_Id;
    return new Promise((resolve, reject) => {
      this.http.get<any>(this.config.data.url + "appointment/getappointments?app_id=" + this.config.data.app_id + params).subscribe(data => {
        resolve(data);
      }, error => {
        reject();
      })
    });

  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { AppointmentService } from 'src/app/services/appointment.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import * as moment from 'moment';

@Component({
  selector: 'app-slot',
  templateUrl: './slot.component.html',
  styleUrls: ['./slot.component.scss']
})
export class SlotComponent implements OnInit {
  @Input() staffId: number;
  @Input() serviceId: number;
  @Input() selectedDate: any;

  @Output() slotSelected: EventEmitter<any> = new EventEmitter();
  
  slots = [];

  constructor(private appService: AppService, private appointmentService: AppointmentService, private config: AppConfigService) { }

  ngOnInit() {
    this.appointmentService.getAppointmentSlots(this.staffId, this.serviceId, this.selectedDate.toString("YYYY/MM/DD")).then((slots) => {
      this.slots = slots;
    }, (error) => {
      this.slots = [];
    });
  }

  selectSlot(slot) {
    this.slotSelected.emit(slot);
  }

}

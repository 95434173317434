import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ServiceCategoriesComponent } from './pages/service-categories/service-categories.component';

import { MatSelectModule, MatInputModule, MatFormFieldModule, MatSidenavModule, MatCheckboxModule, MatButtonModule, MatSnackBarModule, MatCardModule, MatDialogModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { StaffComponent } from './pages/staff/staff.component';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { SharedModule } from '../shared.module';
import { AdminServicesComponent } from './pages/services/services.component';

@NgModule({
  declarations: [
    DashboardComponent, 
    ServiceCategoriesComponent, 
    AdminServicesComponent,
    ConfirmDialogComponent, 
    StaffComponent, 
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,

    MatSidenavModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSnackBarModule,
    MatCardModule,
    MatDialogModule,
    MatSelectModule,


    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    })
  ],
  entryComponents: [ConfirmDialogComponent]
})
export class AdminModule { }
